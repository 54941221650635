
  
  .pdf-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #D4AF37;
  }
  
  .pdf-container .react-pdf__Page__canvas {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  }
  
  .pdf-container .react-pdf__Page__textContent {
    display: none;
  }
  
  .pdf-container .react-pdf__Page__annotations.annotationLayer {
    display: none;
  }
  
  .pdf-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #D4AF37;
    animation: gradientBg 10s ease infinite;
  }
  
  @keyframes gradientBg {
    0% {
      background-color: #D4AF37;
    }
    50% {
      background-color: #32b932;
    }
    100% {
      background-color: #D4AF37;
    }
  }
  