.socials {
  position: fixed;
  right: 1vw;
  bottom: 3em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.socials a {
  position: relative;
  transition: 0.3s;
  padding: 0.5em;
  text-decoration: none;
  font-size: 1em;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  margin-bottom: 0.5em;
}

.socials a:hover {
  transform: translateY(-5px);
}

#social-li {
  background-color: #006699;
}

#social-htb {
  background-color: #4cbb17;
}

#social-gh {
  background-color: #211F1F;
}

.fa {
  font-size: 1.5em;
}

@media only screen and (max-width: 600px) {
  .socials a {
    width: 2.5em;
    height: 2.5em;
  }
  .fa {
    font-size: 1.8em;
  }
}

@media only screen and (min-width: 601px) {
  .socials a {
    width: 3em;
    height: 3em;
  }
  .fa {
    font-size: 2em;
  }
}
