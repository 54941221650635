.navbar-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #222222;
  color: white;
  font-size: 18px;
  height: 80px;
  padding: 0 40px;
  position: sticky;
  top: 0;
  z-index: 9999;
}

.logo-container{
  /* background: white; */
  max-width: 100%;

}
.navbar-logo {
  width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.navbar-item {
  padding: 0 10px;
}

.navbar-link {
  color: #b7e0d8;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
}

.navbar-link:hover {
  color: #7c65ad;
}

/* Hamburger menu styles */

.navbar-menu-icon {
  cursor: pointer;
  display: none;
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .navbar-menu-icon {
    display: block;
  }

  ul {
    display: none;
    position: fixed;
    top: 80px;
    left: 0;
    background-color: rgba(56, 89, 129, 0.9);
    width: 100%;
    padding-top: 20px;
    text-align: center;
    /* overflow: hidden;  */
  }

  ul.navbar-show {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  .navbar-item {
    padding: 18px 10px;
    width: 100%;
  }

  .navbar-logo {
    margin-left: 20px;
  }
}

@media screen and (min-width: 769px) {
  ul {
    display: flex;
    align-items: center;
  }

  .navbar-menu-icon {
    display: none;
  }

  .navbar-item {
    padding: 18px 10px;
  }
}
